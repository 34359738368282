import React from "react"
import { graphql } from "gatsby"
import Styles from "../styles/impressum.module.css"
import HeaderMenu from "../components/header/top-header-with-menu/topHeaderWithMenu"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent";

const Datenshutz = ({ data, location }) => {
  const cookieConsentRef = React.createRef();
  return (
    <div>
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <Helmet htmlAttributes={{ lang: "de" }}>
        <title>{data.datoCmsSeiten.seo.title}</title>
        <meta name="description" content={data.datoCmsSeiten.seo.description} />
      </Helmet>
      <HeaderMenu page="Datenschutz" url={location.href} />
      <header className={Styles.sectionContainer}>
        <h1 className={Styles.h1}>
          {data.datoCmsSeiten.header[0]
            ? data.datoCmsSeiten.header[0].headline
            : ""}
        </h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.datoCmsSeiten.header[0]
              ? data.datoCmsSeiten.header[0].subheadline
              : "",
          }}
        ></div>
      </header>
      <main className={`${Styles.sectionContainer} ${Styles.main}`}>
        {data.datoCmsSeiten.content.map((item, index) => {
          return (
            <div key={index}>
              <h4>{item.quoteHeadline}</h4>
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: item.text,
                }}
              ></div>
            </div>
          )
        })}
      </main>
      <Footer cookieConsentRef={cookieConsentRef} />
    </div>
  )
}

export default Datenshutz

export const query = graphql`
  query datenshutz {
    datoCmsSeiten(name: { eq: "Datenschutz" }) {
      seo {
        description
        title
        twitterCard
      }
      id
      slug
      header {
        ... on DatoCmsHeaderBlueCross {
          id
          headline
          subheadline
        }
      }
      content {
        ... on DatoCmsText {
          id
          quoteHeadline
          text
        }
      }
    }
  }
`
